@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.map-view {
		.mapnote {
			padding: 15px 10px 20px 10px;

			@media all and (min-width: 768px) and (max-width: 991px) {
				min-height: unset;
				padding: 20px 10px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				padding: 20px 10px;
			}

			@media all and (min-width: 1200px) and (max-width: 1697px) {
				padding: 18px 10px;
			}
		}

		#sidemap {
			@media all and (min-width: 768px) and (max-width: 991px) {
				height: calc(100vh - 177px);
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				height: calc(100vh - 177px);
			}

			@media all and (min-width: 1200px) and (max-width: 1697px) {
				height: calc(100vh - 300px);
			}
		}
	}

	.mapnote {
		font-size: 16px;
		padding: 15px 0 20px;
		background: #fff;

		a {
			color: var(--primary);

			&:hover {
				color: var(--secondary);
			}
		}

		@media (max-width: 767px) {
			padding: 15px 15px;
			font-size: 12px;
			min-height: 85px;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			font-size: 14px;
			padding-right: 10px;
			min-height: 135px;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			font-size: 14px;
		}
	}

	.mapconsent {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: rgba(26, 121, 187, 0.15);
		flex-direction: column;
		padding: 0 15px;

		.doc-box {}

		.mapconsent-button {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px 0 15px;
			height: 36px;
			display: block;
			line-height: 35px;

			&:hover {
				background: var(--secondary);
			}
		}
	}

	.result-pane {
		width: 100%;

		@media (max-width:992px) {
			padding: 0 20px;
		}

		color: #565a5c;


		.a-color {
			position: relative;
			z-index: 3;
			color: #007da7;
		}

		.result-header {
			padding: 0 35px 15px;

			.srt-bl-top {
				.flex-align-center {
					@media (max-width:992px) {
						display: block !important;
						text-align: right;
					}

					.btn-filter {
						@media (min-width:993px) {
							border-right: 1px solid var(--grey2);
							padding-right: 5px;
							margin-right: 5px;
							margin-bottom: 0;
							top: 0;
						}

						display: inline-block;
						margin-bottom: 5px;
						color: var(--secondary);
						position: relative;
						top:-6px;

						&:hover {
							color: var(--primary);
						}
					}
				}
			}



			@media (max-width:992px) {
				padding: 0;
			}

			.unit-count-h3 {
				color: var(--black);
				font-size: 21px;
				text-shadow: 0 0 3px rgba(0, 0, 0, .3);
				margin: 0;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}

			.sorting-trigger {
				cursor: pointer;
			}

			.alert {
				max-width: 400px;
				margin: 20px auto 30px;
				border: none !important;
				position: relative;

				&.alert-info {
					background-color: #e6f5fc;

					strong {
						font-weight: bold;
						color: var(--secondary);
					}

					span {
						color: #8bbdd4;
					}
				}
			}

		}

		.mysearch {
			margin-bottom: 4px;
		}
	}

	.srt-bl-top {
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 15px;
		margin-bottom: 15px;
		margin-top: 20px;
	}

	.pagination-panel {
		text-align: center;
	}

}


.result-header {
	.my-search-token {
		margin: 2px;
		padding: 5px 14px 6px !important;
		display: inline-block !important;
		border: 1px solid #f0f0f0 !important;
		border-radius: 24px !important;
		background-color: #f0f0f0;
		font-size: 14px;

		.close {
			color: var(--secondary) !important;
			opacity: 1 !important;
			position: relative;
			top: -1px !important;
			margin-left: 5px;
		}
	}
}


.map-view {
	.result-pane {
		display: none;
	}

	.map-pane {
		min-height: unset !important;

		@media (max-width: 991px) {
			min-height: unset !important;
		}

		@media all and (min-width: 768px) and (max-width: 1500px) {
			min-height: 0 !important;
		}
	}
}

.map-pane {
	/* 		
     background-color: #f5f5f5;
     */
	min-height: 100vh !important;

	top: 0;
	position: relative;
	transition: all 0.25s ease 0s;

	#sidemap {
		width: 100%;
		height: 100vh;
		top: 0;
		display: block;

		@media (min-width:768px) {
			height: calc(100vh - 224px);
			top: 0;
		}

		@media (min-width:993px) {
			height: calc(100vh - 264px);
		}

		@media (max-width: 767px) {
			height: calc(100vh - 140px);
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			height: calc(100vh - 279px);
			top: 0;
		}

		@media all and (min-width: 1200px) and (max-width: 1697px) {
			height: calc(100vh - 288px);
		}



		&.affix {
			@media (min-width:768px) {
				height: calc(100vh - 136px);
				top: 0;
				position: -webkit-sticky;
				position: sticky;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				height: calc(100vh - 95px);
			}

			@media (min-width:1200px) {
				height: calc(100vh - 190px);
				top: 110px;
			}

			@media all and (min-width: 1200px) and (max-width: 1697px) {
				height: calc(100vh - 215px);
			}


		}

		>.flex {
			width: 100%;
			height: 100%;

			>div {
				height: 100%;
			}
		}
	}

	.no-map {
		height: calc(100vh - 180px) !important;


		@media (min-width:768px) {
			height: calc(100vh - 182px) !important;
		}

		@media (max-width: 767px) {
			height: calc(100vh - 96px) !important;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			height: calc(100vh - 96px) !important;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			height: calc(100vh - 96px) !important;
		}

		&.affix {
			height: 100vh !important;
		}
	}

	.header {
		display: none;
		padding: 5px;
		background-color: white;
		text-align: right;

		.close {
			font-size: 46px;
			float: none;
			font-weight: normal;
		}
	}

	&.affix {


		@media (min-width:768px) {
			width: 50%;
			right: 0;
			position: fixed;
			margin-top: 0 !important;
			top: 70px;
			height: calc(100vh - 150px);
		}

		@media (max-width: 1500px) and (min-width: 768px) {
			width: 40%;
		}


	}


}

.map-unit-preview {
	color: #565a5c;
	text-align: center;

	a {
		color: inherit;
	}

	.prices {
		font-size: 14px;
		margin-top: 6px;
		font-weight: 400;
	}

	.preview-img {
		min-height: 150px;

		img {
			max-width: 225px;
		}
	}

	.lazy-preview-placeholder {
		height: 350px;
		width: 240px;
	}

}


.results,
.spinner-panel,
.more-btn-panel {
	background-color: #f5f5f5;
}

.more-btn-panel {
	padding-bottom: 40px;
}

.spinner-panel {
	padding-bottom: 20px;
}

.filter-map-bl {
	@media (min-width:993px) {
		position: absolute;
		right: -220px;
	}

	.btn-filter {
		color: var(--white);
		border: none;
		line-height: 20px;
		background-color: transparent;

		font-family: var(--d-font2);

		@media (max-width:992px) {
			color: var(--black);
			font-size: 13px;
			text-decoration: underline;

		}
	}

	.map-btn {
		padding-left: 10px;
		margin-left: 5px;
		border-left: 1px solid #fff;

		@media (max-width:992px) {
			border-left: 1px solid var(--grey2);
		}

		a {
			color: var(--white);
			font-size: 14px;
			font-family: var(--d-font2);

			@media (max-width:992px) {
				color: var(--black);
				font-size: 13px;
				text-decoration: underline;
			}
		}
	}
}

.results {
	background-color: var(--white);

	@media (max-width:992px) {
		padding: 0;
	}

	@media (min-width:993px) {
		padding: 0 35px !important;
	}

	.no-search-results {
		margin-top: 20px;
		margin-bottom: 27px;
		width: 100%;
		text-align: center;
	}

	.altheading {
		color: red;
	}

	h4 {
		margin-top: 0;
		color: var(--black);
	}



	.srow {
		position: relative;
		cursor: pointer;

		@media (min-width:993px) {
			margin-left: 15px;
			padding: 0;
			box-shadow: 0 0 5px rgba(0, 0, 0, .18);
			border-radius: 5px;
			margin-bottom: 35px;
			border: 2px solid #d6d6d6;
		}

		@media (max-width:992px) {
			margin: 0 auto 20px;
			max-width: 440px;
		}

		&:hover {
			border-color: var(--secondary);
		}

		.map-marker {
			display: inline-block;
			width: 30px;
			height: 50px;
			text-align: center;

			.fa-map-marker-alt {
				position: relative;
				top: 12px;
				cursor: pointer;
				font-size: 24px;
				color: var(--primary);
			}

			.fa-map-marker-alt:hover,
			.fa-map-marker-alt.selected {
				color: #565a5c;
			}
		}

		.img-col {
			box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
			border-radius: 5px;
			overflow: hidden;
			position: relative;
			max-height: 270px;

			.owl-carousel {
				overflow: hidden;

				.owl-nav {
					button {
						width: 50px;
						height: auto;
						bottom: 0;
						top: 0;
						position: absolute;
						border-radius: 0;
						font-size: 0;
						cursor: pointer;
						transition: all 0.25s ease 0s;
						transform: none;
						color: var(--white);
						font-size: 46px;
						line-height: 8px;
						text-align: center;
					}

					.owl-prev {
						background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1);
						left: 0;
					}

					.owl-next {
						right: 0;
						background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1);
					}
				}

				.owl-dots {
					position: absolute;
					width: 70%;
					bottom: 10px;
					padding: 0;
					margin: 0;
					list-style: none;
					text-align: center;
					line-height: 1;
					left: 50%;
					transform: translateX(-50%);

					.owl-dot {
						background-color: #fff;
						width: 6px !important;
						height: 6px !important;
						opacity: .25;
						margin: 0 1px !important;
						border-radius: 50%;

						&.active {
							opacity: 1;
						}
					}
				}
			}

			@media (min-width:993px) {
				left: -16px;
				top: -15px;
			}

			.img-content {

				.full-img {
					width: 100%;
					height: auto;

				}


				.fav-icon {
					position: absolute;
					cursor: pointer;
					right: 7px;
					top: 5px;
					color: #fff;
					margin: 3px;
					font-size: 18px;
					width: 39px;
					text-align: center;
					height: 33px;

					line-height: 31px;
					z-index: 1;

					@media (min-width:993px) {
						background-color: rgba(0, 0, 0, .3);
						border-radius: 5px;
					}
				}


			}


		}

		.info-col {
			position: relative;
			padding: 25px 15px;

			.show-mob {
				.unit-distance {
					background-color: var(--white);
					border-radius: 15px;
					position: absolute;
					top: -55px;

					@media (max-width:992px) {
						top: -36px;
					}
				}
			}

			@media (max-width:992px) {
				margin: 0 10px;
				padding: 15px;
				position: relative;
				background-color: #fff;
				background-color: var(--white);
				top: -35px;
				border-radius: 5px;
				z-index: 5;
				box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
			}



			.region-bl {
				display: inline-flex;
				align-items: center;
				position: absolute;
				top: 5px;
				right: 10px;

				span {
					color: var(--black);
					opacity: .5;
					font-size: 13px;
				}
			}

			.text-truncate {
				color: var(--secondary);
				font-size: 13px;
				font-family: var(--d-font2);
				margin-bottom: 20px;
			}

			.flex-align-center {
				padding-bottom: 14px;
				border-bottom: 1px solid #f2f2f2;
				margin-bottom: 14px;
			}

			.search-result-properties {
				margin: 0 !Important;

				li {
					display: inline-block;
					list-style: none;
					text-align: center;
					float: left;
					padding: 0 0 0 10px;

					@media (max-width:360px) {
						padding-left: 5px;
					}

					i {
						font-size: 16px;
						color: var(--d-grey);
						font-style: normal;
						width: 28px;
						height: 28px;
						background-color: var(--grey);
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						@media (max-width:360px) {
							font-size: 14px;
							width: 26px;
							height: 26px;
						}

						&.cross {
							&:after {
								width: 1.5px;
								height: 100%;
								position: absolute;
								content: "";
								background-color: var(--d-grey);
								-webkit-transform: rotate(-45deg);
								transform: rotate(-45deg);
							}
						}
					}
				}
			}

			.unit-title {
				font-size: 18px;
				color: var(--black);
				padding-right: 104px;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}

			.unit-price-rat {
				display: -webkit-box;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: justify;
				justify-content: space-between;
				-webkit-box-align: center;
				align-items: center;

				.prices {
					position: relative;
					z-index: 2;
					min-width: 160px;
				}


				.unit-link {
					text-transform: uppercase;
					color: var(--primary);
					font-size: 13px;
					font-weight: 700;
					position: relative;
					top: 2px;
				}
			}

			.alternatives {

				font-size: 13px;
				margin-bottom: 15px;

				.altheading {
					color: var(--red);
					font-family: var(--d-font2);
					margin-bottom: 10px;
					display: block;
				}

				.li-bullet {

					li {
						list-style: none;
						background-color: #ecf7fd;
						padding: 10px 15px;
						color: var(--black);
						opacity: .5;
						border: 1px solid #cfebf9;
						font-family: var(--d-font2);
						border-bottom: none;

						&:first-child {
							border-radius: 5px 5px 0 0;
						}

						&:last-child {
							border-radius: 0 0 5px 5px;
							border-bottom: 1px solid #cfebf9;
						}
					}
				}
			}

			.offer-price-box {
				.main-price {
					strong {
						text-decoration: line-through;
					}
				}

				.discount-price[title="Last-Minute"] {
					strong {
						color: var(--red);
					}
				}

				>.discount-price {
					font-size: 21px;
					font-weight: 600;
					color: var(--blue3);
				}
			}

			.offer-bl-srch {
				text-align: center;
				padding-top: 14px;
				border-top: 1px solid #f2f2f2;
				margin-top: 14px;

				.offer-box {
					background-image: url(RESOURCE/img/bg-offer-vector.png);
					position: relative;
					padding: 10px 20px;
					display: inline-flex;
					align-items: center;
					background-repeat: no-repeat;
					background-position: center;
					max-width: 433px;
					background-size: 413px;

					.offer-text {
						background-image: url(RESOURCE/img/bg-offer-text.png);
						background-repeat: no-repeat;
						background-position: center;
						text-transform: uppercase;
						font-size: 13px;
						width: 116px;
						height: 38px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-transform: uppercase;
						font-weight: bold;
						background-size: 116px;
						color: var(--black);
					}

					ul {
						li {
							font-size: 13px;
							color: var(--secondary);
							font-weight: bold;
							list-style: none;
							padding-left: 15px;
						}
					}
				}
			}



			.rentRate {
				p {
					margin: 0;

					strong {
						font-size: 21px;
						font-weight: 600;
						color: var(--blue3);

						@media (max-width:992px) {
							font-size: 18px;
						}
					}

					.price-bl {
						display: inline-block;

						i.fa {
							color: #7f7f7f;
							font-size: 12px;
							margin-left: 5px;
						}

						.price-start {
							display: inline-block;
						}
					}

					span {
						font-size: 12px;
						color: #7e7e7e;
						display: block;
						line-height: 18px;
					}
				}

				a {
					color: var(--black);
				}

				.price-info {

					i {
						color: #7f7f7f;
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}

			.unit-dt-right {
				display: flex;
				align-items: center;
			}

			.unit-distance {
				padding: 0 15px;
				font-weight: bold;
				right: 0;
				top: 0;
				font-size: 12px;
				line-height: 30px;
				color: rgba(0, 0, 0, .7);
				background-color: #e6f2f7;
				border-radius: 15px;
			}

			.stars {
				position: absolute;
				right: 0;
				bottom: 0;
			}

			.unit-place {
				font-size: 16px;
				font-family: var(--d-font2);


				li {
					display: inline-block;
					position: relative;
					padding-left: 10px;
					margin-left: 5px;
					color: var(--black);

					@media (max-width:360px) {
						font-size: 14px;
						padding-left: 3px;
					}

					&:first-child {
						padding-left: 0;
						margin-left: 0;

					}

					i {
						font-size: 18px;
						opacity: .2;
						margin-right: 3px;

						@media (max-width:360px) {
							font-size: 15px;
							margin-right: 2px;
						}
					}
				}
			}

			.prices {
				.price {
					font-size: 20px;
				}
			}

			.search-result-properties {
				margin-top: 5px;
				margin-bottom: 15px;
			}


		}
	}
}

.sort-price {
	position: relative;
	padding-right: 18px;

	&:before,
	&:after {
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}

	&:before {
		border-bottom: 5px solid #ccc;
		right: 0;
		top: 4px;
	}

	&:after {
		border-top: 5px solid #ccc;
		right: 0;
		top: 13px;
	}

	.sorting-trigger {
		color: var(--secondary);
		font-size: 14px;
	}

	span {
		color: #cccccc;
		font-size: 14px;
	}
}

.search-cont {
	padding: 0 35px;
	margin-top: 30px;

	@media (max-width:992px) {
		padding: 0;
	}

	h2 {
		font-size: 28px;
	}

	p {
		color: var(--grey3);
		font-size: 16px;
		line-height: 1.5;
	}

	.btn-groups {
		.btn {
			&.btn-default {
				padding: 5px 14px 6px !important;
				display: inline-block !important;
				border: 1px solid #f0f0f0 !important;
				border-radius: 24px !important;
				background-color: #f0f0f0;
				font-size: 14px;
				margin: 0 10px 10px 0;

				&:hover {
					background-color: var(--primary);
					border-color: var(--primary);
				}
			}
		}
	}
}


.search-result-properties {
	display: inline-block;

	>div {
		float: left;
		text-align: center;
		padding: 3px;

		border-right: 1px solid #ccc;

		:last-child {
			font-weight: bold;
		}
	}

	>div:last-child {
		border: none;
	}

}



.search-bar {
	padding: 5px 10px;
	position: relative;

	@media (max-width:992px) {
		z-index: 40;
	}

	.layout-column {
		@media (min-width:993px) {
			margin-top: 70px;
		}
	}

	.box.layout-column {
		margin-top: unset;
	}

	.daterange {
		display: inline-block;

		@media (max-width:992px) {
			display: block;
		}
	}

	@media (max-width:768px) {
		.sm-full {
			display: block;

		}
	}
}

@media (max-width:767px) {

	.list-view {
		.map-pane {
			display: none;
		}
	}

	.map-view {
		.map-pane {
			@mixin flex;
		}
	}


	.map-pane.full-screen {
		display: flex;
		position: fixed !important;
		left: 0px !important;
		right: 0px !important;
		bottom: 0px !important;
		top: 0px !important;
		z-index: 50;

		.header {
			display: block;
		}

	}

	.pagination-panel {
		display: none;
	}

}

@media (max-width:1500px) and (min-width:768px) {
	.result-pane {
		max-width: 60% !important;

	}

	.map-pane {
		@mixin flex;

	}
}

@media (min-width:993px) {
	.map-pane {
		@mixin flex;

	}

	.result-pane {
		width: 100%;
		max-width: 50%;
		min-height: 750px;

		.more-btn-panel {
			display: none;
		}


	}


	.results {


		.srow {
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			flex-direction: row;


			.info-col {
				width: 60%;
				position: relative;

				.price-anchor {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 80%;
				}
			}

			.img-col {
				@mixin flex;
				position: relative;

				.img-content {
					position: absolute;
					@mixin size100;

					.item {
						height: 270px;
					}


					.full-img {
						height: 100%;
						object-fit: cover;

					}
				}

			}

		}
	}
}

.map-unit-preview {
	.region-bl {
		display: inline-flex;
		align-items: center;
		position: absolute;
		top: 5px;
		right: 10px;

		span {
			color: var(--black);
			opacity: .5;
			font-size: 13px;
		}
	}

	.unit-distance {
		padding: 0 15px;
		font-weight: 700;
		right: 10px;
		top: 10px;
		font-size: 12px;
		line-height: 30px;
		color: rgba(0, 0, 0, .7);
		background-color: #e6f2f7;
		border-radius: 15px;
		position: absolute;
	}

	.text-truncate {
		color: var(--secondary);
		font-size: 13px;
		font-family: var(--d-font2);
		margin-bottom: 25px;
	}

	.flex-align-center {
		padding-bottom: 14px;
		border-bottom: 1px solid #f2f2f2;
		margin-bottom: 14px;
	}

	.search-result-properties {
		margin: 0 !Important;

		li {
			display: inline-block;
			list-style: none;
			text-align: center;
			float: left;
			padding: 0 0 0 10px;

			i {
				font-size: 16px;
				color: var(--d-grey);
				font-style: normal;
				width: 28px;
				height: 28px;
				background-color: var(--grey);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				&.cross {
					&:after {
						width: 1.5px;
						height: 100%;
						position: absolute;
						content: "";
						background-color: var(--d-grey);
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}

			}
		}
	}

	.unit-title {
		font-size: 18px;
		color: var(--black);
	}

	.price-bl {
		font-size: 12px;
		color: #7e7e7e;

		strong {
			font-size: 21px;
			font-weight: 600;
			color: var(--blue3);
		}
	}

	.unit-place {
		font-size: 15px;
		font-family: var(--d-font2);

		li {
			display: inline-block;
			position: relative;
			padding-left: 4px;
			margin-left: 5px;
			color: var(--black);

			&:first-child {
				padding-left: 0;
				margin-left: 0;

			}

			i {
				font-size: 15px;
				opacity: .2;
				margin-right: 4px;
			}
		}
	}
}



.search-bar {
	background: rgb(8, 157, 227);
	background: linear-gradient(318deg, rgba(8, 157, 227, 1) 0%, rgba(10, 207, 254, 1) 100%);
	padding: 25px 0;
	margin-top: 74px;
	text-align: center;

	@media (max-width:992px) {
		margin-top: 0;
	}

	.panel-title,
	label {
		text-transform: uppercase;
	}

	.mob-input-bl {
		margin-left: 50px;
		position: relative;
		margin-right: 20px;
		color: var(--grey2);

		span {
			box-shadow: 0 0 10px rgba(0, 0, 0, .2);
			background-color: var(--white);
			padding: 13px 15px;
			border-radius: 3px;
			font-size: 14px;
			display: block;
			padding-right: 5px;

			@media (max-width:340px) {
				font-size: 13px;
			}
		}

		.icon-left {
			position: absolute;
			left: -30px;
			font-size: 24px;
			top: 8px;
		}
	}

	.searchbar {
		position: relative;
		text-align: left;
		display: block;

		.checkbox.checkbox-success label {
			vertical-align: text-bottom !important;
			line-height: 16px;
		}

		@media (min-width:993px) {
			left: -110px;
			display: inline-block;
			border-radius: 30px;
			margin: 0 auto;
			background-color: var(--white);
			padding-left: 15px;
			box-shadow: 0 0 15px rgba(0, 0, 0, .2);
		}

		.close-filter {
			@media (max-width:992px) {
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba(0, 0, 0, .5);
				z-index: 10;
			}
		}



		.search-form-area {
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: justify;
			justify-content: space-between;

			@media (max-width:992px) {
				flex-wrap: wrap;
				background: var(--white);
				top: 0;
				margin: 0 15px;
				position: absolute;
				z-index: 50;
				box-shadow: 0 0 15px rgba(0, 0, 0, .2);
				padding: 15px 10px;
				border-radius: 5px;
			}

			.form-flex {
				padding: 0 8px;

				@media (max-width:992px) {
					width: 100%;
				}

				.form-bx-area {
					padding-right: 50px;
					position: relative;
					width: 100%;

					&:first-child {
						@media (max-width:1100px) {
							padding-right: 10px;
						}
					}

					@media (max-width:992px) {
						padding-right: 0;
						border-bottom: 2px solid var(--grey);
						padding-bottom: 3px;
						margin-bottom: 15px;
					}

					.close {
						@media (max-width:992px) {
							position: absolute;
							width: 25px;
							height: 25px;
							border-radius: 50%;
							text-align: center;
							color: var(--black);
							font-size: 15px;
							box-shadow: 0 0 15px rgba(0, 0, 0, .2);
						}
					}

					.search-popup-trigger {
						@media (max-width:1130px) and (min-width:993px) {
							max-width: 130px;
						}
					}

					.datepicker-trigger {
						>button {
							min-width: 160px;
							text-align: left;

							@media (max-width:1130px) {
								min-width: 130px;
							}
						}
					}
				}

				.form-control {
					background-color: var(--white);
					border-radius: 0;
					width: 100%;
					height: inherit;
					box-shadow: inherit;
					font-family: var(--dfont);
					border: none;
					font-weight: bold;
					padding-right: 0;
					font-size: 15px;

					@media (max-width:992px) {
						height: 38px;
						padding-left: 48px;
						top: 5px;
					}
				}

				.far {
					position: absolute;
					right: -6px;
					top: 12px;
					color: var(--black);
					z-index: 2;
					font-size: 20px;
					pointer-events: none;

					@media (max-width:992px) {
						right: inherit;
						left: 0;
						top: 5px;
						font-size: 28px;
						color: var(--grey);
					}

					&.shadow {
						font-size: 32px;
						top: 0;
						color: var(--grey);
						z-index: 1;
						right: 9px;

						@media (max-width:992px) {
							display: none;
						}
					}
				}
			}

			.form-btn {

				.btn {
					-webkit-box-align: center;
					align-items: center;
					right: -15px;
					display: -webkit-box;
					display: flex;
					-webkit-box-pack: center;
					justify-content: center;
					font-size: 18px;
					border-radius: 30px;
					font-family: var(--dfont2);
					font-weight: 700;
					height: 60px;
					line-height: 1;
					position: relative;

					i {
						margin-left: 8px;
						color: rgba(0, 0, 0, .25);
						font-size: 20px;
						margin-right: 12px;

						@media (max-width:992px) {
							font-size: 16px;
							margin-right: 6px;
						}
					}

					@media (max-width:992px) {
						font-size: 13px;
						position: relative;
						height: 35px;
						padding-right: 15px;
						right: 0;
					}
				}
			}
		}
	}
}

.vo-search-body {
	.mainHeader {
		@media (max-width:992px) {
			display: none !Important;
		}
	}
}